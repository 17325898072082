<template>
  <VFooter dark color="thunder" class="px-0 pb-0 pt-xs-4">
    <VContainer class="container--padded container--maxed footer-layout pt-4 pb-5">
      <div class="d-none d-lg-block layout-logo">
        <a href="#" class="brand-logo d-block">
          <VImg :src="logo" :alt="logoText" />
        </a>
      </div>

      <FooterSection v-if="socialItems.length" class="footer__follow" title="Follow Us">
        <a v-for="(item, i) in socialItems" :key="i" :href="item.href" target="_blank" class="mr-3">
          <VIcon size="28">mdi-{{ item.type }}</VIcon>
        </a>
      </FooterSection>

      <FooterSection v-if="contact.length" class="footer__contact" title="Contact Us">
        <ul>
          <li v-for="(item, i) in contact" :key="i">{{ $vuetify.lang.t(item.label) }} <a :href="getItemTel(item.tel)"
              class="font-weight-bold">{{
                item.tel }}</a></li>
        </ul>
      </FooterSection>

      <FooterSection v-if="more.length" class="footer__more" title="More information">
        <ul>
          <li v-for="({ href, to }, i) in more" :key="i">
            <a v-if="href" v-bind="href">{{ $vuetify.lang.t(href.label) }}</a>
            <router-link v-else-if="to" v-bind="to">{{ $vuetify.lang.t(to.label) }}</router-link>
          </li>
        </ul>
      </FooterSection>
    </VContainer>

    <div class="footer__copyright">
      <VContainer class="py-lg-8" />
    </div>
  </VFooter>
</template>

<script>
import { defineComponent } from 'vue';
import FooterSection from './FooterSection.vue';

export default defineComponent({
  name: "UniversalFooter",
  props: {
    social: {
      type: Array,
      default: () => [],
    },
    contact: {
      type: Array,
      default: () => [
        { label: "US Sales", tel: "+1 407-967-7121" },
        { label: "US Support", tel: "+1 407-412-9400" },
      ],
    },
    more: {
      type: Array,
      default: () => [
        {
          href: {
            label: 'Privacy Policy',
            href: 'https://docs.google.com/document/d/e/2PACX-1vQxg9R0gGfe5JZuBSr6CzLEAp6w2BKG8plVZ5igHT15ssEgyDEV6UzdvTLwDwZG57t2GBGNeOwEWfVq/pub',
            target: '_blank',
          },
        },
        {
          href: {
            label: 'Terms of Use',
            href: 'https://docs.google.com/document/d/e/2PACX-1vRvTlAmpIPGj31wJQuNLWI7hfu7AANeciANoZRzx2I_IxH7T166YDvh-gmTe0DoMIT04Lw3cEmu7-cY/pub',
            target: '_blank',
          },
        },
        {
          href: {
            label: 'Disclaimer',
            href: 'https://docs.google.com/document/d/e/2PACX-1vTaxfkhQkwAXiIzQdwktVz3JQr6ABVgITmV6f4uaFQIe0G1q79ndJXPEYqPIu1UaJssdJNSiXwmDpr3/pub',
            target: '_blank',
          },
        },
        {
          href: {
            label: 'Cookies Policy',
            href: 'https://docs.google.com/document/d/e/2PACX-1vTo5RgU1-f5L9-PGVavP2VLBFB_Py62pVC7zoESm6VbS822KeJGv0qj6XOqvcCy2dFtcA3ZEcv_nZRs/pub',
            target: '_blank',
          },
        },
      ],
    },
    logo: {
      type: String,
      default: '/img/logo/footer.svg',
    },
    logoText: {
      type: String,
      default: "FlightScope Logo",
    },
  },
  methods: {
    getItemTel(tel) {
      return `tel:${tel.replace(/[\s-]/g, "")}`;
    },
  },
  components: { FooterSection },
  computed: {
    socialItems() {
      let href;

      return this.social.map(({ type, channelID }) => {
        switch (type) {
          case 'facebook':
            href = `https://www.facebook.com/${channelID}`;
            break;
          case 'twitter':
            href = `https://twitter.com/${channelID}`;
            break;
          case 'instagram':
            href = `https://www.instagram.com/${channelID}`;
            break;
          default:
            href = '';
        }

        return {
          href,
          type,
        };
      });
    },
  }
})
</script>

<style lang="scss" scoped>
ul {
  list-style: none;
  padding: 0;
  margin: 0;
  line-height: 2;
}

a {
  text-decoration: none;
  color: var(--v-white--base)
}

section+section {
  margin-top: $grid-gutter * 3;
}

@media #{map-get($display-breakpoints, 'lg-and-up')} {
  section+section {
    margin-top: 0;
  }

  .footer-layout {
    margin-bottom: 90px;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: auto auto;
    gap: 40px 20px
  }

  .layout-logo {
    grid-area: 1/1/3/5
  }

  .footer__follow {
    grid-area: 1/5/2/8
  }

  .footer__contact {
      grid-area: 2/5/3/8
  }

  .footer__more {
      grid-area: 1/8/3/10
  }

  .footer__copyright {
    background: #000;
    width: 100%;

    p {
      margin-bottom: 0;
    }
  }
}
</style>
