<template>
  <section v-bind="$attrs">
    <h3 class="font-nunito mb-4 font-weight-semibold">{{ $vuetify.lang.t(title) }}</h3>
    <slot></slot>
  </section>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'FooterSection',

  props: {
    title: {
      type: String,
      default: '',
    },
  },
})
</script>
